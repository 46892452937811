import { toColumnName } from '../../../common/functions/helpers';

export default {
  data() {
    return {
      tamanhoColunas: [],
      estiloTitulo: {
        textAlign: 'center',
        bold: true,
        enable: false,
        background: this.$vuetify.theme.currentTheme.primary,
        color: 'rgb(255,255,255)',
      },
      estilos: [],
      formulas: [],
      estiloAzulClaro: { background: 'rgb(207, 226, 243)', bold: true },
      estiloCinza: { background: 'rgb(188, 197, 211)' },
      estiloFundoBranco: { background: 'rgb(255,255,255)', bold: false },
      estiloLinhaPrincipal: { background: 'rgb(188, 197, 211)', bold: false },
      estiloHabilitado: { enable: true, background: 'rgb(255,255,255)', bold: false },
      estiloSomenteLeitura: { enable: false, color: 'rgb(0,0,0)' },
    };
  },
  methods: {
    aplicaEstiloColunas() {
      this.estilos = [];
      this.formulas = [];
      this.aplicaTamanhoColunas();
      this.aplicaEstiloCabecalho();
      this.aplicaEstiloDesabilitado();
      this.aplicaEstiloCampoNumerico();
      this.aplicaEstiloCoresNasCelulas();
      this.aplicaEstiloLinhaPrincipal();
      this.aplicaEstiloHabilitadoParaLinhasEspecificas();
      this.aplicaNegritoColunaTotal();

      this.aplicaFormulaTotalLateral();
      // TODO: essa formula substitui a celula de saldo
      // verificar remocao
      // this.aplicaFormulaTotalInferior();
    },
    aplicaEstiloCabecalho() {
      const letraInicial = toColumnName(0);
      const letraFinal = toColumnName(this.colunas.length - 1);
      this.estilos.push({ range: `${letraInicial}1:${letraFinal}1`, metodos: this.estiloTitulo });
    },
    aplicaEstiloCampoNumerico() {
      if (this.somenteLeitura) return;

      const colunas = [
        ...this.capturaColunasPorTipo('DINHEIRO'),
      ];
      colunas.forEach((coluna) => {
        const { letra } = coluna;
        const max = coluna.max || 9999999999.99,
          min = coluna.min || -9999999999.99;

        this.estilos.push({
          range: `${letra}${this.linhaInicial}:${letra}${this.ultimaLinhaEscrita}`,
          metodos: { format: '[>=0][BLACK]###,##0.00;[RED]###,##0.00' },
        });
        this.formulas.push({
          range: `${letra}${this.linhaInicial}:${letra}${this.ultimaLinhaEscrita}`,
          metodos: {
            validation: {
              allowNulls: true,
              comparerType: 'between',
              from: min,
              to: max,
              dataType: 'number',
              type: 'reject',
              messageTemplate:
                this.$t('message.intervalo_numerico', { min, max }),
              titleTemplate: this.$tc('title.erro', 1),
            },
          },
        });
      });
    },
    aplicaEstiloCoresNasCelulas() {
      const range = this.obterRangePosPosseConta();
      this.estilos.push({
        range: `${range[0]}${this.linhaInicial}:${range[1]}${this.ultimaLinhaEscrita}`,
        metodos: this.estiloAzulClaro,
      });
    },
    aplicaEstiloDesabilitado() {
      if (!this.somenteLeitura) {
        const letras = this.colunas.map((coluna, indice) => {
          if (coluna.disabled) {
            return toColumnName(indice);
          }
          return null;
        }).filter((c) => c != null);

        letras.forEach((letra) => {
          this.estilos.push({
            range: `${letra}${this.linhaInicial}:${letra}${this.ultimaLinhaEscrita}`,
            metodos: this.estiloSomenteLeitura,
          });
        });
      } else {
        const letraInicial = toColumnName(0);
        const letraFinal = toColumnName(this.colunas.length - 1);
        const numeroFinal = this.linhasInvestimento.length + 1;
        this.estilos.push({
          range: `${letraInicial}${this.linhaInicial}:${letraFinal}${numeroFinal}`,
          metodos: this.estiloSomenteLeitura,
        });
      }
    },
    posseContaNaoPredefinida(posseConta) {
      return posseConta !== this.$tc('label.planejado', 1)
        && posseConta !== this.$tc('label.comprometido', 1)
        && posseConta !== this.$tc('label.saldo', 1);
    },
    aplicaEstiloHabilitadoParaLinhasEspecificas() {
      const numeros = this.datasource.map((linhaSpreadsheet, indice) => {
        if (!linhaSpreadsheet.principal
            && this.posseContaNaoPredefinida(linhaSpreadsheet.posseConta)) {
          return `${indice + 2}`;
        }
        return null;
      }).filter((num) => num != null);

      const rangePeriodo = this.obterRangeColunasPeriodo();
      numeros.forEach((numero) => {
        this.estilos.push({
          range: `${rangePeriodo[0]}${numero}:${rangePeriodo[1]}${numero}`,
          metodos: this.estiloHabilitado,
        });
      });

      const range = this.obterRangePosPosseConta();
      numeros.forEach((numero) => {
        this.estilos.push({
          range: `${range[0]}${numero}:${range[0]}${numero}`,
          metodos: this.estiloFundoBranco,
        });
      });
    },
    aplicaEstiloLinhaPrincipal() {
      const linhasPosseConta = this.datasource.map((linhaSpreadsheet, indice) => {
        if (linhaSpreadsheet.principal) {
          return `${indice + 2}`;
        }
        return null;
      }).filter((num) => num != null);

      const letraInicial = toColumnName(0);
      const letraFinal = toColumnName(this.colunas.length - 1);

      linhasPosseConta.forEach((linhaPosseConta) => {
        this.estilos.push({
          range: `${letraInicial}${linhaPosseConta}:${letraFinal}${linhaPosseConta}`,
          metodos: this.estiloLinhaPrincipal,
        });
      });
    },
    aplicaFormulaTotalInferior() {
      const aplicouPeriodo = this.colunasPeriodos.length > 0;
      if (!aplicouPeriodo) return;

      const linhasSaldo = this.obterLinhaSpreadsheet(this.$tc('label.saldo', 1));
      const linhasComprometido = this.obterLinhaSpreadsheet(this.$tc('label.comprometido', 1));
      const linhasPosseConta = this.datasource.map((linhaSpreadsheet, indice) => {
        if (linhaSpreadsheet.posseConta && !linhaSpreadsheet.descricao
          && this.posseContaNaoPredefinida(linhaSpreadsheet.posseConta)) {
          return `${indice + 2}`;
        }
        return null;
      }).filter((num) => num != null);

      for (let i = this.colunasExibicao.length; i <= this.colunas.length - 2; i += 1) {
        const letraAtual = toColumnName(i);
        linhasSaldo.forEach((linhaSaldo, indice) => {
          const soma = `SUM(${letraAtual}${linhasPosseConta[indice]}-${letraAtual}${linhasComprometido[indice]})`;
          this.formulas.push({
            range: `${letraAtual}${linhaSaldo}`,
            metodos: { formula: `${soma}` },
          });
        });
      }
    },
    aplicaFormulaTotalLateral() {
      const aplicouPeriodo = this.colunasPeriodos.length > 0;
      if (!aplicouPeriodo) return;

      const letraInicial = toColumnName(this.colunasExibicao.length),
        letraFinal = toColumnName(this.colunas.length - 2),
        letraRange = toColumnName(this.colunas.length - 1),
        linhaInicial = 2;

      for (let i = linhaInicial; i <= this.ultimaLinhaEscrita; i += 1) {
        const soma = `SUM(${letraInicial}${i}:${letraFinal}${i})`;
        this.formulas.push({
          range: `${letraRange}${i}`,
          metodos: { formula: `${soma}` },
        });
      }
    },
    aplicaNegritoColunaTotal() {
      const letraFinal = toColumnName(this.colunas.length - 1);
      this.estilos.push({
        range: `${letraFinal}${this.linhaInicial}:${letraFinal}${this.ultimaLinhaEscrita}`,
        metodos: { bold: true },
      });
    },
    aplicaTamanhoColunas() {
      this.tamanhoColunas = this.colunas.map((coluna) => {
        if (this.colunasExibicao.indexOf(coluna) >= 0) {
          return { width: 200 };
        }
        return { width: 150 };
      });
    },
    capturaColunasPorTipo(tipo) {
      return this.colunas.map((coluna, indice) => {
        const { type, attributes, field } = coluna;
        if (type === tipo) {
          return {
            letra: toColumnName(indice),
            field,
            ...(attributes),
          };
        }
        return null;
      }).filter((c) => c != null);
    },
    obterRangePosPosseConta() {
      const letraPosseConta = this.colunas.map((coluna, indice) => {
        if (coluna.field === 'posseConta') return toColumnName(indice);
        return null;
      }).filter((l) => l != null);

      const letraTotal = this.colunas.map((coluna, indice) => {
        if (coluna.field === 'total') return toColumnName(indice);
        return null;
      }).filter((l) => l != null);
      return [...letraPosseConta, ...letraTotal];
    },
    obterLinhaSpreadsheet(label) {
      return this.datasource.map((linhaSpreadsheet, indice) => {
        if (linhaSpreadsheet.posseConta === label
          && !linhaSpreadsheet.principal
          && !linhaSpreadsheet.descricao) {
          return `${indice + 2}`;
        }
        return null;
      }).filter((num) => num != null);
    },
    obterRangeColunasPeriodo() {
      const letrasPeriodo = [];
      this.colunasPeriodos.forEach((colunaPeriodo) => {
        const letra = this.colunas.map((coluna, indice) => {
          if (coluna.field === colunaPeriodo.field) {
            return toColumnName(indice);
          }
          return null;
        }).filter((l) => l != null);

        letrasPeriodo.push(letra);
      });

      const letraInicial = letrasPeriodo[0],
        letraFinal = letrasPeriodo.length > 1
          ? letrasPeriodo[letrasPeriodo.length - 1]
          : letrasPeriodo[0];

      return [letraInicial, letraFinal];
    },
  },
};
