<template lang="html">
  <div>
    <orcamento-rateio-detalhes
      :rateio="rateio"
      :configuracao="configuracao"
      v-if="configuracaoCarregada && !recarregando" />

    <orcamento-rateio-spreadsheet
      :rateio="rateio"
      :configuracao="configuracao"
      :divisoes="divisoes"
      :divisao-selecionada="divisaoSelecionada"
      :celula-inicial="celulaInicial"
      :planilha-pronta="planilhaPronta"
      v-if="configuracaoCarregada && !recarregando"
      @OrcamentoRateioSpreadsheet__recarregaRateio="recarregaRateio"
      @OrcamentoRateioSpreadsheet__atualizarWorkspace="atualizarWorkspace"
      @OrcamentoRateioSpreadsheet__recarregarDivisoes="carregarDivisoes"
      @OrcamentoRateioSpreadsheet__carregarRateio="carregarRateio"/>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { showLoading, hideLoading } from '../../../common/functions/loading';
import OrcamentoConfiguracao from '../OrcamentoConfiguracao';
import OrcamentoRateioDetalhes from './OrcamentoRateioDetalhes';
import OrcamentoRateioSpreadsheet from './OrcamentoRateioSpreadsheet';

export default {
  name: 'OrcamentoRateio',
  props: {
    copia: Boolean,
    somenteLeitura: Boolean,
    edicao: Boolean,
    novo: Boolean,
    fluxo: Boolean,
  },
  data() {
    return {
      extensaoResources: this.$api.extensao(this.$resource),
      rateioResources: this.$api.orcamentoRateio(this.$resource),
      configuracaoResource: this.$api.orcamentoConfiguracao(this.$resource),
      workspaceFiltrosResources: this.$api.workspaceFiltros(this.$resource),

      configuracao: null,
      metadadosOrcamento: null,
      recarregando: false,
      celulaInicial: 'A2',
      divisoes: [],
      divisaoSelecionada: null,
      workspace: {},
      rateio: null,
      planilhaPronta: false,
    };
  },
  components: {
    OrcamentoRateioDetalhes,
    OrcamentoRateioSpreadsheet,
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    canAccessPageRateio() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'ORC_RATEIO').length;
    },
    configuracaoCarregada() {
      return this.configuracao != null;
    },
  },
  methods: {
    accessForbidden() {
      if (!this.canAccessPageRateio) {
        this.$router.push({ name: 'inicio' });
      }
    },
    carregarRateio(idOrcamento, indRecarregando = false, carregaLinhas = false) {
      showLoading();
      this.recarregando = indRecarregando;
      const parametros = { idOrcamento };
      if (this.divisaoSelecionada) {
        parametros.idDivisao = this.divisaoSelecionada;
      }
      parametros.carregaLinhas = carregaLinhas;
      this.rateioResources.buscaRateio(parametros)
        .then((res) => {
          this.rateio = res.data;
          this.recarregando = false;
        })
        .then(() => this.carregarConfiguracao(idOrcamento))
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    recarregaRateio(ultimaCelula) {
      this.recarregando = true;
      this.celulaInicial = ultimaCelula;

      const { idOrcamento } = this.$route.params;
      const parametros = { idOrcamento };
      if (this.divisaoSelecionada) {
        parametros.idDivisao = this.divisaoSelecionada;
      }

      this.rateioResources.buscaRateio(parametros)
        .then((res) => {
          this.rateio = res.data;
          this.recarregando = false;
          this.planilhaPronta = true;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    carregarConfiguracao(idOrcamento) {
      return this.configuracaoResource.buscarConfigSnapshot({ idOrcamento })
        .then((res) => {
          showLoading();
          this.configuracao = new OrcamentoConfiguracao(res.data);
          if (this.configuracao && !this.configuracao.rateioDivisao) {
            this.planilhaPronta = true;
          }
          if (this.rateio.linhasInvestimento.length && this.rateio.qtdeLinhas > 1000) {
            window.setTimeout(() => hideLoading(), 120000);
          }
        });
    },
    carregarDivisoes(idOrcamento) {
      this.rateioResources.buscarDivisoes({ idOrcamento })
        .then((res) => {
          this.divisoes = res.data || [];
          this.carregarWorkspaceRateioDivisao(idOrcamento);
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    carregarWorkspaceRateioDivisao(idOrcamento) {
      const entidade = 'rateio_divisao';
      this.workspaceFiltrosResources.pesquisarPorIdEntidade({ entidade, idEntidade: idOrcamento })
        .then((response) => {
          this.workspace = response.data || {};
          if (this.divisoes.length && this.divisoes.length > 0) {
            if (this.workspace.filtros && this.workspace.filtros.divisao) {
              const temAcessoADivisaoWorkspace = !!this.divisoes
                .filter((d) => d.id === this.workspace.filtros.divisao).length;
              if (!temAcessoADivisaoWorkspace) {
                this.divisaoSelecionada = this.divisoes[0].id;
                this.atualizarWorkspace(this.divisaoSelecionada);
              } else {
                this.divisaoSelecionada = this.workspace.filtros.divisao;
              }
            } else if (this.workspace.filtros) {
              if (this.divisaoSelecionada) {
                this.workspace.filtros.divisao = this.divisaoSelecionada;
                this.atualizarWorkspace(this.divisaoSelecionada);
              }
            } else {
              this.divisaoSelecionada = this.divisoes[0].id;
              this.inserirWorkspace(idOrcamento);
            }
          }
        })
        .then(() => this.carregarRateio(idOrcamento))
        .catch((err) => {
          this.$error(this, err);
        });
    },
    inserirWorkspace(idOrcamento) {
      const entidade = 'rateio_divisao';
      this.workspace.idEntidade = idOrcamento;
      this.workspace.filtros = {};
      this.workspace.filtros.divisao = this.divisaoSelecionada;

      this.workspaceFiltrosResources.inserir({ entidade }, this.workspace)
        .then((response) => {
          this.workspace.id = response.data;
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    atualizarWorkspace(divisao, ultimaCelula = null) {
      this.divisaoSelecionada = divisao;
      if (ultimaCelula) {
        this.recarregaRateio(ultimaCelula);
      }
      const entidade = 'rateio_divisao';
      this.workspace.filtros.divisao = divisao;

      this.workspaceFiltrosResources
        .atualizar({ entidade }, this.workspace)
        .catch((err) => {
          this.$error(this, err);
        });
    },
  },
  mounted() {
    if (!this.canAccessPageRateio) {
      window.setTimeout(() => this.accessForbidden(), 1E3);
      return;
    }
    const { idOrcamento } = this.$route.params;
    this.carregarDivisoes(idOrcamento);
  },
};
</script>
